import * as yup from 'yup';
import { profileDataFormFields } from '../fields/formFields';
import { emailSchema } from './common/emailSchema';
import './common/extends';

export const getProfileDataFormSchema = (opts: { phoneChanged?: boolean; emailChanged?: boolean }) => {
  const { phoneChanged = false, emailChanged = false } = opts;

  let phoneOtpCheck = yup
    .string()
    .matches(/^[0-9]+$/, ' ')
    .min(4, ' ')
    .max(4, ' ');

  let emailOtpCheck = yup
    .string()
    .matches(/^[0-9]+$/, ' ')
    .min(6, ' ')
    .max(6, ' ');

  if (phoneChanged) {
    phoneOtpCheck = phoneOtpCheck.required(' ');
  }

  if (emailChanged) {
    emailOtpCheck = emailOtpCheck.required(' ');
  }

  return yup.object({
    [profileDataFormFields.phone]: yup.string().trim().required('missingField').phone('invalidFormat'),
    [profileDataFormFields.phoneOtp]: phoneOtpCheck,
    [profileDataFormFields.email]: emailSchema,
    [profileDataFormFields.emailOtp]: emailOtpCheck,
    // [profileDataFormFields.address]: yup.string()
    //   .trim()
    //   .required("missingField"),
    [profileDataFormFields.employerAddress]: yup.string().trim().required('missingField'),
    [profileDataFormFields.companyName]: yup.string().trim().required('missingField'),
    [profileDataFormFields.fullName]: yup.string().trim().required('missingField'),
    [profileDataFormFields.birthDate]: yup.string().trim().optional().nullable(),
    [profileDataFormFields.nationalId]: yup.string().trim().optional().nullable(),
  });
};
