import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import RequestInvestSuccessfulScreen from '../components/User/Invest/RequestInvestSuccessfulScreen';
import { ar2En } from '../utils/number';
import { SubscribedPortfolio } from '../store_deprecated/types/portfolioTypes';
import { getAddMoreFundToPortfolioSelector } from '../store_deprecated/selectors/portfolioSelectors';
import PaymentMethods, { PaymentMethodsType } from './PaymentMethods';
import PayWithMadaOrVisaOrMasterCard from './PayWithMadaOrVisaOrMasterCard';

import PayWithApple from './PayWithApple';
import AddFundFromWallet from './AddFundFromWallet';
import { requestAddMoreFundToPortfolioReset } from '../store_deprecated/actions/portfolioActions';
import { useTypedDispatch } from '../redux/store';

interface AddMoreFundDialogProps {
  open: boolean;
  showSuccessfulScreen?: boolean;
  hidePayButton?: boolean;
  closeDialog: (success?: boolean) => void;
  onSuccessButtonClick?: () => void;
  subscribedPortfolio?: SubscribedPortfolio;
  minAmount?: number;
  defaultSelectedMethod?: PaymentMethodsType;
  excludedPaymentMethods?: PaymentMethodsType[];
  shouldShowProductList?: boolean;
}

export default function AddMoreFundDialog(props: AddMoreFundDialogProps) {
  const {
    open,
    showSuccessfulScreen = false,
    closeDialog = () => {},
    onSuccessButtonClick = () => {},
    subscribedPortfolio,
    minAmount = 1000,
    defaultSelectedMethod = 'WALLET',
    excludedPaymentMethods = [],
    hidePayButton,
    shouldShowProductList,
  } = props;

  const [fundAmount, setFundAmount] = useState('');

  const addMoreFundToPortfolioData = useSelector(getAddMoreFundToPortfolioSelector);
  const { isLoading } = addMoreFundToPortfolioData;

  const dispatch = useTypedDispatch();
  const [PaymentMethod, setPaymentMethod] = useState<PaymentMethodsType>(defaultSelectedMethod);

  useEffect(() => {
    setFundAmount('');
    setPaymentMethod(defaultSelectedMethod);
  }, [open]);

  const handleOnPaymentMethodClick = (paymentName: PaymentMethodsType) => {
    setPaymentMethod(paymentName);
  };

  const handleClose = () => {
    if (isLoading) {
      return;
    }
    dispatch(requestAddMoreFundToPortfolioReset());
    setFundAmount('');
    closeDialog();
  };

  const parseValue = (v: string | number) =>
    `${v}`
      // eslint-disable-next-line no-useless-escape
      .replace(/([\.\-\,])(?=.*\1)/g, '')
      // eslint-disable-next-line no-useless-escape
      .replace(/[^.\-\d]/g, '');

  const handleAmountField = (event: any) => {
    const initialValue = event.target.value;

    const newValue: string = parseValue(ar2En(initialValue));
    const newValueNumber = +newValue;

    // setValue(newValue);

    if (Number.isNaN(newValueNumber) || newValue.endsWith('.') || !newValue) {
      event.target.value = newValue;

      setFundAmount(event.target.value);

      return;
    }

    event.target.value = `${newValueNumber}`;

    setFundAmount(event.target.value);

    sessionStorage.setItem('lastPaymentAmount', `${newValueNumber}`);
  };

  const PaymentMethodChild = (
    <PaymentMethods
      excludedPaymentMethods={excludedPaymentMethods}
      selectedId={PaymentMethod}
      handleClick={handleOnPaymentMethodClick}
    />
  );

  const renderContent = () => {
    if (PaymentMethod === 'WALLET') {
      return (
        <AddFundFromWallet
          PaymentMethodChild={PaymentMethodChild}
          amount={fundAmount}
          closeDialog={closeDialog}
          handleAmountField={handleAmountField}
          handleClose={handleClose}
          minAmount={minAmount}
          subscribedPortfolio={subscribedPortfolio}
        />
      );
    }
    if (PaymentMethod === 'MASTER') {
      return (
        <PayWithMadaOrVisaOrMasterCard
          closeDialog={closeDialog}
          PaymentMethodChild={PaymentMethodChild}
          providerName="hyperPayVisaOrMaster"
          addCardProviderName="addCardHyperPayVisaOrMaster"
          shouldShowProductList={shouldShowProductList}
          amount={fundAmount}
          handleAmountField={handleAmountField}
        />
      );
    }
    if (PaymentMethod === 'VISA') {
      return (
        <PayWithMadaOrVisaOrMasterCard
          closeDialog={closeDialog}
          PaymentMethodChild={PaymentMethodChild}
          providerName="hyperPayVisaOrMaster"
          addCardProviderName="addCardHyperPayVisaOrMaster"
          shouldShowProductList={shouldShowProductList}
          amount={fundAmount}
          handleAmountField={handleAmountField}
        />
      );
    }
    if (PaymentMethod === 'MADA') {
      return (
        <PayWithMadaOrVisaOrMasterCard
          providerName="hyperPayMada"
          closeDialog={closeDialog}
          PaymentMethodChild={PaymentMethodChild}
          addCardProviderName="addCardHyperPayMada"
          shouldShowProductList={shouldShowProductList}
          amount={fundAmount}
          handleAmountField={handleAmountField}
        />
      );
    }
    if (PaymentMethod === 'APPLE') {
      return (
        <PayWithApple
          PaymentMethodChild={PaymentMethodChild}
          closeDialog={closeDialog}
          amount={fundAmount}
          handleAmountField={handleAmountField}
          shouldShowProductList={shouldShowProductList}
        />
      );
    }
  };

  return (
    <Dialog open={open} onClose={handleClose} disableScrollLock>
      <Box sx={{ padding: '32px 24px' }}>
        {!showSuccessfulScreen ? (
          renderContent()
        ) : (
          <RequestInvestSuccessfulScreen onSuccessButtonClick={onSuccessButtonClick} />
        )}
      </Box>
    </Dialog>
  );
}
