import * as APIs from '../../constants/APIs';
import {
  AddMoreFundToPortfolioPayload,
  FindSubscriptionsHistoryPayload,
  RedemptionPayload,
  SubscribeToPortfolioPayload,
} from '../../store_deprecated/types/portfolioTypes';
import { FindTransactionsHistoryPayload } from '../../store_deprecated/types/transactionTypes';
import { ApiService } from '../http';
import { toQueryParams } from '../request';

const HTTP = new ApiService();

export async function getRecommendedPortfolio(category: string) {
  try {
    return await HTTP.get(`${APIs.GET_RECOMMENDED_PORTFOLIO}/${category}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getRecommendedPortfolioByProductId(productId: number, category?: string) {
  try {
    const queryParams = category ? `?category=${category}` : '';
    return await HTTP.get(`${APIs.GET_RECOMMENDED_PORTFOLIO_BY_PRODUCT}/${productId}${queryParams}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getSubscribedPortfolio() {
  try {
    return await HTTP.get(`${APIs.GET_SUBSCRIBED_PORTFOLIO}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doSubscribeToPortfolio(payload: SubscribeToPortfolioPayload) {
  try {
    return await HTTP.post(`${APIs.DO_SUBSCRIBE_TO_PORTFOLIO}`, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function doAddMoreFundToPortfolio(payload: AddMoreFundToPortfolioPayload) {
  try {
    return await HTTP.post(`${APIs.DO_ADD_MORE_FUND_TO_PORTFOLIO(payload.portfolioId)}`, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getSubscriptionsHistory(payload: FindSubscriptionsHistoryPayload) {
  const { ...data } = payload;
  try {
    return await HTTP.get(`${APIs.GET_SUBSCRIPTIONS_HISTORY}?${toQueryParams(data)}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function redeemPortfolioRequest({ subscribedPortfolioId, ...payload }: RedemptionPayload) {
  try {
    return await HTTP.post(`${APIs.REDEEM_PORTFOLIO}/${subscribedPortfolioId}/redeem`, payload);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function cancelSubscriptionRequest(id: number) {
  try {
    return await HTTP.post(`${APIs.CANCEL_SUBSCRIPTION}/${id}/cancel`, {});
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getTransactionsHistory(payload: FindTransactionsHistoryPayload) {
  const { ...data } = payload;
  try {
    return await HTTP.get(`${APIs.GET_TRANSACTIONS_HISTORY}?${toQueryParams(data)}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getPortfolioLatestStatistics() {
  try {
    return await HTTP.get(`${APIs.GET_PORTFOLIO_LATEST_STATISTICS}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getPortfolioStatistics() {
  try {
    return await HTTP.get(`${APIs.GET_PORTFOLIO_STATISTICS}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getIndexFundData(id: number) {
  try {
    return await HTTP.get(`${APIs.GET_INDEX_FUND_DATA}/${id}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getUserIndexFundsData() {
  try {
    return await HTTP.get(`${APIs.GET_USER_INDEX_FUNDS_DATA}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getFundChartData(id: number) {
  try {
    return await HTTP.get(`${APIs.GET_FUND_CHART_DATA}/${id}/chart-data`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getAvailablePortfoliosRequest() {
  try {
    return await HTTP.get(`${APIs.GET_PORTFOLIO}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}

export async function getPortfolioByID(id: number) {
  try {
    return await HTTP.get(`${APIs.GET_PORTFOLIO}/${id}`);
  } catch (error) {
    HTTP.handleError(error);
  }
}
